@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: Inter, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 3.2rem;
  font-weight: 700;
  margin-block-end: 0.3em;
}

button {
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

input {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  display: inline-block;
}

input:focus {
  outline: 2px solid white;
}

input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

p{
  font-weight: 500;
  display: inline-block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}