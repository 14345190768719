.App {
  text-align: center;
  font-family: 'Inter';
  position: relative;
  min-height: 100vh;
  width: 100vw;
  --main-color: #22092C;
  --second-color: #872341;
  --third-color: #BE3144;
   --fourth-color: #F05941;
   --white-color: white;
   --black-color: black;
   overflow: hidden;
   color: var(--white-color);
   background: rgb(34,9,44);
   background: linear-gradient(135deg, var(--black-color) 0%, var(--main-color) 100%);
   display: flex;
}

.Circle{
  background-color: var(--white-color);
  position: absolute;
  width: 20vw;
  height: 20vw;
  border-radius: 20vw;
  opacity: 5%;
  filter: blur(30px);
}

#Circ1{
  --time: 10s;
  --distance: 150px;
  --distanceY: 60px;
  left: -5vw;
  top: 50vh;
  animation: move var(--time)  infinite alternate;
}

#Circ2{
  --time: 10s;
  --distance: 100px;
  --distanceY: 100px;
  left: 70vw;
  top: 5vh;
  animation: move var(--time) infinite alternate;
}

#Circ3{
  --time: 10s;
  --distance: -50px;
  --distanceY: -150px;
  left: 85vw;
  top: 15vh;
  animation: move var(--time)  infinite alternate;
}

#Circ4{
  --time: 10s;
  --distance: -100px;
  --distanceY: -100px;
  left: 15vw;
  top: 70vh;
  animation: move var(--time)  infinite alternate;
}

@keyframes move {
  from {
    transform: translate(0px,0px);
  }
  to {
    transform: translate(var(--distance),var(--distanceY));
  }
}

@keyframes load {
  from {
    transform: translateY(-30px);
    opacity: 0%;
  }
  to {
    transform: translateY(0px);
    opacity: 100%;
  }
}

.Header{
  position: absolute;
  height: 10vh;
}

#logo{
  height: 100%;
}

.Content{
  margin: auto;
  display: flex;
  width: 80%;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 60px;
  animation: load 2s ease;
  z-index: 3;
  margin-block-start: 150px;
}

.heroText{
  text-align: middle;
}

.heroTitle{
  text-align: middle;
  margin-block-end: 1em;
}

.wrapper {
  max-width: 500px;
  position: relative;
  margin-block-start: 1.5em;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.wrapper button {
  background: white;
  border-radius: 20px;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  font-size: 1.1rem;
  text-wrap: nowrap;
  font-weight: 500;
  background: linear-gradient(135deg, var(--white-color), rgb(248 185 171));
}

.wrapper input {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 20px;
  padding: 1rem 2rem;
  width: 60%;
  flex-grow: 100;
}

.animation{
  flex-grow: 2;
  background-color: var(--white-color);
  border-radius: 20px;
  overflow: hidden;
  margin-block-end: 200px;

  video {
    border-radius: 20px;
    width: 100%;
    outline: none;
  }
}

@media (min-width: 800px) {
  .wrapper {
    flex-direction: row;
    gap: 15px;
  } 
  .animation{
    margin-block-end: unset;
  }
}

/* Styles for extra large devices */
@media (min-width: 1200px) {
  .Content{
    margin: auto;
    margin-block-start: auto;
    display: flex;
    width: 80%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 10%;
    animation: load 2s ease;
    z-index: 3;
  }

  .heroText{
    max-width: 45%;
    text-align: left;
  }

  .heroTitle{
    margin-block-end: 0.3em;
  }

  .wrapper {
    width: 100%;
    max-width: 100%;
    position: relative;
    margin: 0;
    margin-block-start: 1.5em;
  }
}

.coloredText {
  position: relative; /* Make the span a positioned element */
  color: var(--fourth-color);
}

.coloredText::after {
  content: ''; /* Add content to the pseudo-element */
  position: absolute; /* Position the pseudo-element */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 20px solid rgba(233,99,75, 0.2);
  border-radius: 20px;
  box-sizing: border-box; /* Include padding and border in the total width and height */
}

.coloredText2 {
  position: relative; /* Make the span a positioned element */
  color: var(--third-color)
}

.coloredText2::after {
  content: ''; /* Add content to the pseudo-element */
  position: absolute; /* Position the pseudo-element */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 20px solid rgba(175,61,71, 0.2);
  border-radius: 20px;
  box-sizing: border-box; /* Include padding and border in the total width and height */
}

.benefit{
  background-color: rgba(255,255,255, 0.05);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  width: auto;
}

.emoji{
  margin-right: 5px;
}